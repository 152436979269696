<template>
  <div class="Events">
    <!-- 轮播图 -->
    <van-swipe :autoplay="3000" :show-indicators="false">
      <van-swipe-item v-for="(item, index) in lunboList" :key="index">
        <img class="van-swipe-item-img" v-lazy="item.image" />
      </van-swipe-item>
    </van-swipe>

    <!-- Events -->
    <div class="Events-box w1330">
      <div class="Events-title text-align b800">EVENTS</div>
      <div class="Events-list">
        <div
          class="Events-list-item flex pointer"
          v-for="item in elist"
          :key="item.id"
          @click="$router.push(`/Detail?id=${item.id}`)"
        >
          <div class="Events-list-item-cover">
            <img :src="item.image" />
          </div>
          <div class="Events-list-item-right">
            <div class="Events-list-item-title f-color3 f26 b600 oneline">
              {{ item.title }}
            </div>
            <div class="Events-list-item-intro f-color3 f26 threeline">
              {{ item.detail }}
            </div>
            <div class="Events-list-item-more flex align-center">
              <span class="f-color3 f26">See more</span>
              <img src="../assets/arrow-right-black.png" />
            </div>
            <div class="Events-list-item-time flex flex-end">
              <span class="f-color3 f26">{{
                $api.timeForMat(item.createtime)
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 分页器 -->
      <pager @before="pageClick(-1)" @next="pageClick(1)"></pager>
    </div>

    <!-- Activities -->
    <div class="Activities-main relative">
      <div class="Activities-footer absolute"></div>

      <div class="Activities-box w1330">
        <div class="Activities-title text-align b800">ACTIVITIES</div>
        <ul class="Activities-list flex flex-wrap">
          <li
            class="Activities-list-item flex flex-cos align-center pointer"
            v-for="item in list"
            :key="item.id"
            @click="$router.push(`/AcDetail?id=${item.id}`)"
          >
            <div class="list-item-cover">
              <img :src="item.image" />
            </div>
            <span class="list-item-title oneline f26 b800">{{
              item.title
            }}</span>
            <div class="list-item-intro twoline f26">
              {{ item.detail }}
            </div>
          </li>
        </ul>
        <!-- 分页器 -->
        <pager @before="pageClick1(-1)" @next="pageClick1(1)"></pager>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import pager from "../components/pager.vue";

export default {
  name: "Events",
  data() {
    return {
      lunboList: [],
      epage: 1,
      elimit: 3,
      elist: [],
      page: 1,
      limit: 3,
      list: [],
    };
  },
  components: {
    pager,
  },
  created() {
    this.getAboutUs();
    this.getEvents();
    this.getList();
    this.setNavbarCurrent(this.$route.path);
  },
  methods: {
    ...mapActions(["setNavbarCurrent"]),
    //获取关于我们
    async getAboutUs() {
      // var data = (await this.$api.Get('index/gongsi')).data
      // this.lunboList = data.banner
      this.lunboList = [
        {
          image: "/Introduce/1.webp",
          status_text: "",
          position_text: "",
        },
      ];
    },
    //事件列表
    async getEvents() {
      var res = await this.$api.Get(
        `index/importantEvents?position=incident&page=${this.epage}&limit=${this.elimit}`
      );
      this.elist = res.data;
    },
    async pageClick(step) {
      var idx = this.epage + step;
      if (idx < 1) {
        this.toast("没有更多数据");
      } else {
        const loading = this.$loading({
          lock: true,
          text: "Loading",
          background: "rgba(255, 255, 255, 0.7)",
        });
        var res = await this.$api.Get(
          `index/importantEvents?position=incident&page=${idx}&limit=${this.elimit}`
        );
        if (res.data.length) {
          this.epage = idx;
          this.elist = res.data;
          this.scroll(".Events-box");
        } else this.toast("没有更多数据");
        loading.close();
      }
    },

    //活动列表
    async getList() {
      var res = await this.$api.Get(
        `index/importantEvents?position=group&page=${this.page}&limit=${this.limit}`
      );
      this.list = res.data;
    },
    async pageClick1(step) {
      var idx = this.page + step;
      if (idx < 1) {
        this.toast("没有更多数据");
      } else {
        const loading = this.$loading({
          lock: true,
          text: "Loading",
          background: "rgba(255, 255, 255, 0.7)",
        });
        var res = await this.$api.Get(
          `index/importantEvents?position=group&page=${idx}&limit=${this.limit}`
        );
        if (res.data.length) {
          this.page = idx;
          this.list = res.data;
          this.scroll(".Activities-box");
        } else this.toast("没有更多数据");
        loading.close();
      }
    },
    //滚动至区块
    scroll(el) {
      var dis =
        document.querySelector(el).offsetTop -
        document.querySelector("#navbar").clientHeight;
      document.documentElement.scrollTop = dis;
    },
    //弹窗提示
    toast(message) {
      this.$message({
        type: "warning",
        message,
        offset: 100,
      });
    },
  },
};
</script>
<style lang="less" scoped>
.van-swipe-item-img {
  display: block;
  width: 100%;
  height: calc(100vh - 71px);
  object-fit: cover;
}

.Events-title {
  padding: 90px 0;
  line-height: 1;
  font-size: 80px;
  font-family: Saira;
  color: #f96f2f;
}

.Events-list-item:nth-of-type(n + 2) {
  margin-top: 110px;
}

.Events-list-item:hover {
  .Events-list-item-cover img {
    transform: scale(1.12);
  }

  .Events-list-item-title,
  .Events-list-item-intro {
    color: #f96f2f;
  }
}

.Events-list-item-cover {
  width: 315px;
  height: 243px;
  overflow: hidden;
}

.Events-list-item-cover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.3s ease-in-out;
}

.Events-list-item-right {
  flex: 1;
  margin-left: 24px;
}

.Events-list-item-intro {
  margin: 30px 0 10px;
}

.Events-list-item-more img {
  margin-left: 10px;
  width: 7px;
  height: 12px;
}

.Events-list-item-time {
  margin-top: 10px;
}

.Activities-main {
  min-width: 1330px;
  padding: 200px 0;
  background: url(../assets/gray-bg.png) no-repeat top / 1920px 1250px;
}

.Activities-footer {
  bottom: 0;
  width: 100%;
  height: 240px;
  background-color: #f5f5f7;
  z-index: -1;
}

.Activities-title {
  padding: 90px 0;
  line-height: 1;
  font-size: 80px;
  font-family: Saira;
  color: #f96f2f;
}

.Activities-list-item {
  margin-right: 100px;
  width: 372px;
}

.Activities-list-item:nth-of-type(3n) {
  margin-right: 0;
}

.Activities-list-item:nth-of-type(n + 4) {
  margin-top: 50px;
}

.list-item-cover {
  width: 100%;
  height: 242px;
  box-shadow: 0px 12px 9px 0px rgba(6, 0, 1, 0.25);
  overflow: hidden;
}

.list-item-cover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.3s ease-in-out;
}

.Activities-list-item:hover {
  .list-item-cover img {
    transform: scale(1.12);
  }

  .list-item-title,
  .list-item-intro {
    color: #f96f2f;
  }
}

.list-item-title {
  margin: 30px 0 20px;
  color: #484848;
  transition: 0.3s ease-in-out;
  word-break: break-all;
}

.list-item-intro {
  line-height: 30px;
  color: #2a2a2a;
  word-break: break-all;
  transition: 0.3s ease-in-out;
}

@media screen and (max-width: 750px) {
  .van-swipe-item-img {
    height: auto;
  }

  .Events-box {
    width: 300px;
  }

  .Events-title {
    padding: 35px 0 30px;
    font-size: 20px;
  }

  .Events-list-item:nth-of-type(n + 2) {
    margin-top: 28px;
  }

  .Events-list-item-cover {
    width: 96px;
    height: 79px;
  }

  .Events-list-item-right {
    margin-left: 8px;
  }

  .Events-list-item-title {
    font-size: 13px;
  }

  .Events-list-item-intro {
    margin: 4px 0;
    font-size: 12px;
  }

  .Events-list-item-more {
    display: none;
  }

  .Events-list-item-time {
    margin-top: 0;
    justify-content: flex-start;
  }

  .Events-list-item-time span {
    font-size: 9px;
  }

  .Activities-main {
    min-width: 300px;
    padding: 60px 0;
    background-size: auto 380px;
  }
  .Activities-footer {
    height: 100px;
  }
  .Activities-box {
    width: 300px;
  }

  .Activities-title {
    padding: 35px 0 30px;
    font-size: 20px;
  }

  .Activities-list-item {
    width: 84px;
    margin-right: 24px;
  }

  .Activities-list-item:nth-of-type(n + 4) {
    margin-top: 24px;
  }

  .list-item-cover {
    width: 100%;
    height: 53px;
  }

  .list-item-title {
    margin: 10px 0 6px;
    font-size: 11px;
  }

  .list-item-intro {
    font-size: 8px;
    line-height: 1.3;
  }
}
</style>
